<template>
  <van-popup
    v-model="isShow"
    round
    position="bottom"
    close-icon="close-tttt"
    :style="{ height: height }"
  >
    <div class="filter-model">
      <div class="filter-mode-title">
        {{ title }}
        <i
          class="icon-joyo"
          @click="closeModel"
        >&#xe620;</i>
      </div>
      <div class="filter-model-collapse">
        <van-collapse
          v-model="activeNames"
          :border="false"
        >
          <template
            v-for="(node) in nodes"
          >
            <van-collapse-item
              v-show="!hiddens[node.field]"
              :key="node.field"
              :class="{'boder-hidden': activeNames.includes(node.field)}"
              :title="node.title"
              :name="node.field"
            >
              <component
                :is="node.componentName"
                :config="node"
              />
            </van-collapse-item>
          </template>
        </van-collapse>
      </div>
      <div class="filter-model-button">
        <cube-button
          :outline="true"
          class="btn btns reset-btn"
          @click="reset"
        >
          重置
        </cube-button>
        <cube-button
          class="btn btns confirm-btn"
          @click="confirm"
        >
          确定 {{ valNums > 0 ? `(${valNums})` : '' }}
        </cube-button>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { getValueNum } from '@/utils/filterDataHandler'
import GoTree from './components/go-tree'
import GrooveSlider from './components/groove-slider'
import OldCompBranch from './components/old-comp-branch'
import OldCompBranch2 from './components/old-comp-branch2'
import RangeDate from './components/range-date'
import SingleDate from './components/single-date'
import TagSelect from './components/tag-select'
import WeekRangeDate from './components/week-range-date'

export default {
  name: 'FilterPopup',
  components: {
    GoTree,
    GrooveSlider,
    OldCompBranch,
    OldCompBranch2,
    RangeDate,
    SingleDate,
    TagSelect,
    WeekRangeDate
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: '80%'
    },
    title: {
      type: String,
      default: '全部筛选'
    },
    nodes: {
      type: Array,
      default() {
        return []
      }
    },
    data: {
      type: Object,
      default() {
        return {}
      }
    },
    dict: {
      type: Object,
      default() {
        return {}
      }
    },
    num: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isShow: false,
      activeNames: [],
      // hiddens: {}
    }
  },
  computed: {
    valNums() {
      return getValueNum(this.data, this.hiddens)
    },
    hiddens() {
      const hiddens = {}
      this.nodes.forEach(node => {
        if (node.hidden && typeof node.hidden !== 'function') {
          hiddens[node.field] = true
        } else if (typeof node.hidden === 'function') {
          hiddens[node.field] = node.hidden(this.data)
        }
      })
      return hiddens
    }
  },
  provide() {
    return {
      filterModelSelf: this
    }
  },
  watch: {
    isShow(val) {
      this.$emit('update:show', val)
    },
    show: {
      immediate: true,
      handler(val) {
        if (this.isShow !== val) {
          this.isShow = val
        }
      }
    },
    valNums(num) {
      this.$emit('update:num', num)
    },
    nodes: {
      immediate: true,
      handler(nodes) {
        if (!Array.isArray(nodes)) {
          this.activeNames = [];
        } else {
          this.activeNames = nodes.map(item => item.field)
          this.$nextTick(() => {
            this.$emit('filter-reset')
          })
        }
      }
    }
    // nodes() {
    //   this.activeNames = []
    // }
  },
  created() {
  },
  mounted() {
    this.$emit('update:num', this.valNums)
  },
  methods: {
    updated(val) {
      this.$emit('update:data', { ...this.data, ...val })
    },
    reset() {
      this.$emit('update:reset', {})
      this.$emit('filter-reset')
    },
    confirm() {
      this.$emit('confirm')
    },
    closeModel() {
      this.isShow = false
    },
    // setHidden() {
    //   this.hiddens = {}
    // }
  }
}
</script>
<style lang="scss" scoped  src="@/assets/css/components/btn-bottom.scss"></style>
<style lang="scss" scoped>
.filter-model {
  width: 100%;
  height: 100%;
  .filter-mode-title {
    position: relative;
    height: 56px;
    color: #23252E;
    font-weight: 600;
    font-size: 16px;
    font-family: sans-serif, Avenir, Helvetica, Arial;
    line-height: 56px;
    text-align: center;
    .icon-joyo {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
    }
  }
  .filter-model-collapse {
    height: calc(100% - 126px);
    overflow: auto;
  }
  .filter-model-button {
    padding-top: 10px;
    padding-bottom: 20px;
    .btn {
      position: static;
      display: inline-block;
      margin-right: 0;
      margin-left: 0;
      letter-spacing: 2px;
    }
    .reset-btn {
      width: 112px;
      color: #5A5C66;
      background: #F0F1F2;
      border-color: #F0F1F2;
    }
    .confirm-btn {
      width: 221px;
      margin-left: 12px;
    }
    ::v-deep.cube-btn-outline::after {
      border: none;
    }
  }
  .boder-hidden {
    ::v-deep.van-cell::after {
      display: none;
    }
  }
  ::v-deep.van-cell::after {
    right: 0;
    display: block;
    border-bottom: 1px solid #EAEBED;
  }
  ::v-deep.van-cell {
    height: 52px;
    padding: 16px 15px;
    line-height: 20px;
    .van-cell__title {
      text-align: left;
    }
  }
  ::v-deep.van-collapse-item__content {
    padding: 0 15px;
    text-align: left;
  }
  ::v-deep.van-cell__right-icon {
    color: #999BA3;
  }
  ::v-deep.van-collapse-item--border::after {
    border-top: none;
  }
  ::v-deep.van-cell--clickable:active {
    background-color: transparent;
  }
}
</style>
