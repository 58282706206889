<template>
  <div
    class="old-comp-container"
    @click.stop="() => {}"
  >
    <tag-items
      :data="tagList"
      @addItem="addItem"
      @closeItem="delItem"
    />
    <select-picker
      v-show="false"
      ref="picker"
      :data="branchOrgIdList"
      @toTree="toBranchSelect"
    />
    <van-popup
      v-model="isShow"
      :style="{ height: '80%' }"
      position="top"
      :get-container="'body'"
      :close-on-click-overlay="false"
      @click.native.stop="() => {}"
      @close="onClose"
    >
      <div
        class="dialog-detail filter-box marginNo"
      >
        <ul class="filter-ul">
          <select-check
            v-for="(item, index) of branchOrgIdList.options"
            :key="index"
            :data="item"
            @click.native="subscriptOperat(index)"
          />
        </ul>
      </div>
      <div
        class="bottom-div"
      >
        <div
          class="btn-div"
          @click="returns"
        >
          返回
        </div>
        <div
          class="btn-divs"
          @click="ensureBanch"
        >
          确定
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import selectPicker from '_c/picker/select';
import selectCheck from '_c/picker/select-check';

import eventBus from '@/utils/eventBus'
import commMixin from '../../mixins/comm'
import TagItems from '../tag-items'

export default {
  name: 'OldCompBranch',
  components: {
    TagItems,
    selectPicker,
    selectCheck
  },
  mixins: [commMixin],
  data() {
    return {
      isShow: false,
      branchOrgIdList: {
        title: '分公司',
        name: '',
        value: [],
        dap: false,
        options: []
      },
      tagList: []
    }
  },
  computed: {
    ...mapGetters(['dictList', 'branchList'])
  },
  watch: {
    branchList(newValue) {
      this.branchOrgIdList.options = newValue;
      this.getOptions()
    },
    value(val) {
      if (!val || val.length === 0) {
        this.tagList = []
      }
    }
  },
  mounted() {
    this.emitEvent = `old-select-picker1-${this.field}`
    // 获取分公司
    this.getRegionFiliales(2);
  },
  beforeDestroy() {
    eventBus.$off(this.emitEvent)
  },
  methods: {
    ...mapActions(['getRegionFiliales']),
    showPicker() {
      this.$refs.picker.showPicker(this.branchOrgIdList.dap)
    },
    toBranchSelect() {
      this.isShow = true
      // this.$refs.customPopups.isShow = true;
    },
    ensureBanch() {
      const list = [];
      this.branchOrgIdList.options.forEach(item => {
        if (item.dep === true) {
          list.push(item);
        }
      });
      this.changeValue(list)
      this.returns();
    },
    subscriptOperat(index) {
      this.branchOrgIdList.options[index].dep = !this.branchOrgIdList.options[index].dep;
    },
    addItem() {
      this.getOptions()
      this.showPicker()
    },
    changeValue(list) {
      this.tagList = list
      this.value = list.map(item => item.value);
    },
    getOptions() {
      const list = (Array.isArray(this.value) && this.value) || []
      this.branchOrgIdList.options.forEach(item => {
        if (list.includes(item.value)) {
          item.dep = true
        } else {
          item.dep = false
        }
      })
    },
    delItem(item) {
      this.value = this.value.filter(val => val !== item.value)
      this.tagList = this.tagList.filter(tag => tag.value !== item.value)
    },
    returns() {
      this.isShow = false
      // this.$refs.customPopups.isShow = false;
    },
    onClose() {
      this.isShow = false
    },
  }
}
</script>

<style lang="scss" scoped  src="@/assets/css/components/filter.scss"></style>
<style lang="scss" scoped  src="@/assets/css/components/btn-bottom.scss"></style>
<style lang="scss" scoped>
.old-comp-container {
  width: 100%;
  ::v-deep.up-bottom {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3000;
  }
  ::v-deep.mask {
    left: 0;
    z-index: 3000;
  }
  ::v-deep.dialog-box {
    z-index: 3001;
  }
}
.dialog-detail{
  z-index: 102;
  height: calc(100% - 46px);
  overflow-y: auto;
}
.marginNo{
  margin: 0
}
.bottom-div {
  >div {
    text-align: center;
  }
}
</style>
