<template>
  <div
    class="old-comp-container"
    @click.stop="() => {}"
  >
    <tag-items
      :data="tagList"
      @addItem="addItem"
      @closeItem="delItem"
    />
    <!-- <select-picker
      v-if="$route.params.i !== '0'"
      :data="filterData.branchOrgId"
    /> -->
    <select-picker
      v-show="true"
      ref="picker"
      :pick-type="'vant'"
      :data="branchOrgIdList"
      @change="pickChange"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import selectPicker from '_c/picker/select';

import commMixin from '../../mixins/comm'
import TagItems from '../tag-items'

export default {
  name: 'OldCompBranch',
  components: {
    selectPicker,
    TagItems
  },
  mixins: [commMixin],
  data() {
    return {
      branchOrgIdList: {
        title: '分公司',
        name: '',
        value: '',
        dap: true,
        options: []
      },
      tagList: [],
      cubeClass: ['fliter-model-pick'],
    }
  },
  computed: {
    ...mapGetters('clue', ['filterObj']),
    ...mapGetters(['dictList', 'branchList'])
  },
  watch: {
    branchList(newValue) {
      this.branchOrgIdList.options = newValue;
    },
    value(val) {
      if (!val) {
        this.tagList = []
      }
    }
  },
  mounted() {
    // 获取分公司
    this.getRegionFiliales(2);
    this.getOptions()
  },
  methods: {
    ...mapActions(['getRegionFiliales']),
    addItem() {
      this.$refs.picker.showPicker(this.branchOrgIdList.dap)
    },
    toBranchSelect() {
      this.$refs.customPopups.isShow = true;
    },
    ensureBanch() {
      const list = [];
      this.branchOrgIdList.options.forEach(item => {
        if (item.dep === true) {
          list.push(item);
        }
      });
      // this.branchOrgIdList.value = list.map(item => item.value);
      // this.branchOrgIdList.name = list.map(item => item.text).join(',');
      this.tagList = list
      this.value = list.map(item => item.value);
      // this.cache();
      this.returns();
    },
    returns() {
      this.$refs.customPopups.isShow = false;
    },
    getOptions() {
      this.branchOrgIdList.options = this.branchList;
    },
    subscriptOperat(index) {
      this.branchOrgIdList.options[index].dep = !this.branchOrgIdList.options[index].dep;
    },
    delItem() {
      this.value = ''
    },
    pickChange(item) {
      this.value = item.value
      this.tagList = [item]
    }
  }
}
</script>

<style lang="scss" scoped  src="@/assets/css/components/filter.scss"></style>
<style lang="scss" scoped  src="@/assets/css/components/btn-bottom.scss"></style>
<style lang="scss" scoped>
.old-comp-container {
  width: 100%;
  ::v-deep.mask {
    left: 0;
  }
  ::v-deep.screen-li {
    height: 0;
    min-height: 0;
    overflow: hidden;
  }
}
.dialog-detail{
  z-index: 102;
  height: calc(90vh - 46px);
  overflow-y: auto;
}
.marginNo{
  margin: 0
}
.bottom-div {
  >div {
    text-align: center;
  }
}
</style>
