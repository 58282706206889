<template>
  <div class="groove-slider">
    <div
      v-if="sliderTitle && value"
      class="slider-title"
    >
      <div class="slider-text">{{ sliderTitle }}</div>
      <div class="slider-num">
        <span class="slider-num-pre">{{ titleBock.numPre }}</span>
        <span class="slider-num-value">{{ sliderNum }}</span>
        <span class="slider-num-unit">{{ titleBock.unit }}</span>
      </div>
    </div>
    <div
      v-else-if="!sliderTitle"
      class="slider-title slider-title-emtry"
    >
      <span>- -</span>
    </div>
    <div
      v-else
      class="slider-title slider-title-placeholder"
    >
      <div class="slider-text">{{ titleBock.placeholder }}</div>
      <div class="slider-num">
        <span>- -</span>
      </div>
    </div>
    <div class="slider-container">
      <div class="slider-content">
        <van-slider
          v-model="sliderNum"
          active-color="transparent"
          inactive-color="transparent"
          :step="step"
          @change="onChange"
        >
          <template slot="button">
            <div class="slier-button">
              <div class="slier-button-point"></div>
            </div>
          </template>
        </van-slider>
        <div class="slider-scale">
          <div
            v-for="item in scaleList"
            :key="item.scale"
            class="scale-item"
            :style="{ left: `${item.scale}%` }"
          >
            <div class="scale-item-point" />
            <div class="scale-item-num">{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commMixin from '../../mixins/comm'

export default {
  name: 'GrooveSlider',
  mixins: [
    commMixin
  ],
  data() {
    return {
      sliderNum: 0,
      isChange: true
    }
  },
  computed: {
    scaleList() {
      let list = [{ scale: 0, text: 0 }, { scale: 10, text: '' }, { scale: 20, text: 20 }, { scale: 30, text: '' }, { scale: 40, text: 40 }, { scale: 50, text: '' }, { scale: 60, text: 60 }, { scale: 70, text: '' }, { scale: 80, text: 80 }, { scale: 90, text: '' }, { scale: 100, text: 100 }]
      if (Array.isArray(this.config.scaleList)) {
        list = this.config.scaleList
      }
      return list
    },
    titleBock() {
      return this.config.titleBock || {}
    },
    step() {
      return this.config.step || 1
    },
    sliderTitle() {
      let title = ''
      const val = `${this.sliderNum}${this.titleBock.unit || ''}`
      this.options.forEach(option => {
        if (val === option.percentage) {
          title = option.text
        }
      })
      return title
    }
  },
  watch: {
    sliderNum(num) {
      if (!this.isChange) return
      const val = `${num}${this.titleBock.unit || ''}`
      if (typeof this.config.getValue === 'function') {
        this.value = this.config.getValue(this.options, val)
      } else {
        this.value = num
      }
    },
  },
  mounted() {
    // update:reset
    this.modelSelf.$on('filter-reset', () => {
      this.sliderNum = 0
      this.isChange = false
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.isChange = true
      }, 10)
    })
  },
  beforeDestroy() {
    this.modelSelf.$off('filter-reset')
  },
  methods: {
    onChange() {}
  },
}
</script>

<style lang="scss" scoped>
.groove-slider {
  width: 100%;
  .slider-title {
    height: 36px;
    margin-bottom: 16px;
    .slider-text {
      height: 20px;
      color: #23252E;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
    }
    .slider-num {
      height: 16px;
      color: #999BA3;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
    }
    .slider-num-pre {
      margin-right: 5px;
    }
  }
  .slider-title-emtry {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
  }
  .slider-title-placeholder {
    .slider-text {
      color: #999BA3;
    }
  }
  .slider-container {
    position: relative;
    height: 58px;
    padding: 0 14px;
    ::v-deep.van-slider {
      top: 13px;
      z-index: 2;
    }
  }
  .slider-container::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 28px;
    background: #F5F5F7;
    border-radius: 14px;
    content: " ";
  }
  .slider-content {
    position: relative;
  }
  .slier-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    .slier-button-point {
      box-sizing: border-box;
      width: 20px;
      height: 20px;
      background: #00A4FF;
      border: 2px solid #FFF;
      border-radius: 50px;
      box-shadow: 0 2px 8px 0 rgba(0,0,0,0.1000);
    }
  }
  .slider-scale {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 58px;
    .scale-item {
      position: absolute;
      width: 30px;
      height: 100%;
      transform: translateX(-50%);
      .scale-item-point {
        position: absolute;
        top: 12px;
        left: 50%;
        width: 4px;
        height: 4px;
        background: #000;
        border-radius: 4px;
        transform: translateX(-50%);
        opacity: 0.1;
      }
      .scale-item-num {
        position: absolute;
        bottom: 8px;
        width: 100%;
        height: 16px;
        color: #C6C7CC;
        line-height: 16px;
        text-align: center;
      }
    }
  }
}
</style>
