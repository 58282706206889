<template>
  <div class="condition-filter">
    <div
      v-if="isTabShow"
      class="condition-tabs"
    >
      <div class="tabs-container">
        <span
          v-for="(item, index) in tabs"
          :key="index"
          class="tab-item"
          :class="{active: tabCode === item.code}"
          @click="tabChange(item)"
        >
          {{ item.label }}
          {{ tabNumShow && item.num > 0 ? `(${item.num + 0 > 99 ? '99+' : item.num})` : '' }}
        </span>
      </div>
      <div class="right-fill">
        <div
          v-if="isFilter"
          class="filter"
          :class="{active: filterNum > 0}"
          @click="toFilter()"
        >
          筛选{{ filterNum > 0 ? `(${filterNum})` : '' }}
          <i class="icon-joyo space_icon">&#xe6f6;</i>
        </div>
      </div>
    </div>
    <div
      v-if="fastList.length"
      class="fast-list"
    >
      <cube-scroll
        ref="scroll"
        :data="fastList"
        direction="horizontal"
        class="horizontal-wrap"
        nest-mode="free"
      >
        <ul class="horizontal-list">
          <li
            v-for="item in fastList"
            :key="item.code"
            class="list-item "
            :class="{active: fastCode === item.code}"
            @touchstart="fastChange(item)"
          >
            {{ item.label }}
            <!-- <div class="text-wrap">
              <span class="text">{{ item.label }}</span>
            </div> -->
          </li>
        </ul>
      </cube-scroll>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConditionFilter',
  model: {
    prop: 'value',
    event: 'updateValue'
  },
  props: {
    tabs: {
      type: Array,
      default() {
        return [
          {
            code: 0,
            label: '我的'
          },
          {
            code: 1,
            label: '本部门及以下'
          }
        ]
      }
    },
    isFilter: {
      type: Boolean,
      default: true
    },
    isTabShow: {
      type: Boolean,
      default: true
    },
    filterNum: {
      type: Number,
      default: 0
    },
    fastList: {
      type: Array,
      default() {
        return []
      }
    },
    value: {
      type: Object,
      default() {
        return {}
      }
    },
    tabNumShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  },
  computed: {
    tabCode() {
      return this.value.tabItem && this.value.tabItem.code
    },
    fastCode() {
      return this.value.fastItem && this.value.fastItem.code
    },
  },
  methods: {
    tabChange(item) {
      this.$emit('updateValue', { ...this.value, tabItem: item })
      this.$emit('tabChange')
    },
    fastChange(item) {
      if (item.code === this.fastCode) {
        this.$emit('updateValue', { ...this.value, fastItem: { code: '', label: '' }})
      } else {
        this.$emit('updateValue', { ...this.value, fastItem: item })
      }
      this.$emit('fastChange')
    },
    toFilter() {
      this.$emit('toFilter')
    }
  }
}
</script>

<style  lang="scss" scoped>
.condition-filter {
  box-sizing: border-box;
  width: 100%;
  // box-shadow: 0 3px 6px -1px #ccc;
  .condition-tabs {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    height: 40px;
    padding: 10px 16px 12px 16px;
    .tabs-container {
      .tab-item {
        height: 18px;
        margin-right: 14px;
        color: #23252E;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        vertical-align: baseline;
        // transition: all 0.3s;
      }
      .tab-item.active {
        color: #00A4FF;
        font-weight: 500;
        font-size: 16px;
      }
    }
    .filter {
      color: #999BA3;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      .icon-joyo {
        vertical-align: middle;
      }
    }
    .filter.active {
      color: #23252E;
    }
  }
  .fast-list {
    height: 40px;
    overflow: hidden;
  }
  .horizontal-wrap {
    width: 100%;
    text-align: left;
    ::v-deep.cube-scroll-list-wrapper {
      display: inline-block;
    }
    ::v-deep.cube-scroll-content {
      display: inline-block;
    }
    .cube-scroll-content {
      display: inline-block;
    }
    .horizontal-list {
      // display: inline-block;
      width: auto;
      padding: 4px 10px 12px 10px;
      overflow: visible;
      white-space: nowrap;
      background: transparent;
      >.list-item:last-child {
        margin-right: 18px;
      }
    }
    .list-item {
      display: inline-block;
      height: 24px;
      margin-right: 8px;
      padding: 0 12px;
      color: #5A5C66;
      font-size: 12px;
      font-family: sans-serif;
      line-height: 24px;
      text-align: center;
      background: #F5F5F7;
      border-radius: 4px;
      transition: all 0.3s;
    }
    .list-item.active {
      color: #00A4FF;
      background: #E8FBFF;
    }
  }
}
</style>
