const config = {
  BASE_URL: process.env.VUE_APP_API_URL,
  SSO_URL: process.env.VUE_APP_SSO_URL,
  ERR_OK: 'S000000', // 操作成功
  ERR_AUTH_EXPIRED: 'E000008', // 身份过期，请重新登录
  ERR_NOT_LOGIN: 'E000021', // 用户未登录
  ERR_FAIL_LOGIN: 'E000022', // 网络异常，请重新登录
  ERR_SIGN_ELSE: 'E000023', // 您的账号已在其他地方登录，请注意账号安全
  ERR_SIGN_OTHER_ELSE: 'E000050', // 您的账号已在其他地方登录，请注意账号安全
  ERR_SIGN_FIRST_RESET: 'ESSO202', // 首次登录未修改密码
  ERR_SIGN_TIME_OUT: 'ESSO203', // 距离上次登陆超过72小时
  ERR_NO_VCODE: 'ESSO110', // 验证码为空
  ERR_FAIL_PASS: 'ESSO003', // 密码错误
  ERR_FAIL_VCODE: 'ESSO043', // 图形验证码错误或已失效
  ERR_REGISTER_EXIT: 'ESSO021', // 注册时用户已存在，去登录
  MAP_KEY: 'd1c1cfa5c1d8249dba564374c0038c12' // 高德地图开发者 key
};

export default config;
